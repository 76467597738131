@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap");
body {
  overflow-x: hidden;
  font-family: "Nanum Gothic", sans-serif;
}

.categoryItemList {
  display: flex;
  justify-content: space-between;
}

.productList {
}
.product img {
}
.product {
}
.productPrice {
}
.productPrice button {
}

.productPrice button:hover {
}

.selected {
  background: #007bff;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .productList {
    grid-template-columns: repeat(2, 1fr);
  }
  .category {
    flex-direction: column;
  }
  .userInfo {
    flex-direction: column;
  }
}
